const md = require('markdown-it')({
  breaks: true,
});
const dedent = require('dedent-js');

const FinanceOptions = [
  {
    tab: 'Financement personnel',
    index: 0,
    content: [
      {
        heading: 'Financement personnel',
        text: md.render(
          dedent`Si vous financez personnellement le bootcamp (en [temps plein](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-web-mobile/) ou en [temps partiel](https://www.lereacteur.io/bootcamp-javascript/formation-developpeur-web-mobile-temps-partiel/)), le règlement peut s’effectuer en trois fois par prélèvement bancaire.
          Un acompte de deux mille euros net (2.000€) sera prélevé pour valider l’inscription à la session choisie. 
          Deux autres prélèvements de sommes identiques interviendront au 7ème jour puis au 37ème jour de la formation.\n &nbsp;
          Pour notre formation en [e-learning + mentoring](https://www.lereacteur.io/cours-informatique/formation-developpeur-web/), un acompte de mille euros net (1.000€) sera prélevé pour valider l’inscription. 
          Trois autres prélèvements de 800 cents euros net (800€) interviendront en début de mois, à partir de début de la formation.
          `
        ),
      },
    ],
  },
  {
    tab: "Demandeur d'emploi",
    index: 1,
    content: [
      {
        heading: 'Compte Personnel de Formation (CPF)',
        text: md.render(
          dedent`Le CPF permet à toute personne, salariée ou demandeur d’emploi, de participer au financement d'une formation. Notre formation est référencée sous le code CPF n°236956. Pour utiliser vos heures CPF, rendez-vous sur [moncompteformation.gouv.fr](https://www.moncompteformation.gouv.fr/) pour connaître la valeur de l'aide qui peut vous être attribuée. La validation du dossier à la session choisie s’effectue le jour même ou le lendemain au plus tard.
          \n &nbsp;
          Si vous financez personnellement le reste à charge de la formation, votre inscription à la session choisie s’effectuera lorsque vous aurez versé un acompte de 2.000€ par prélèvement bancaire.
          \n &nbsp;
          La constitution du dossier CPF n’est plus possible à moins de 11 jours ouvrés du début de la formation.`
        ),
      },
      {
        heading: 'Aide Individuelle à la Formation (AIF) - France Travail',
        text: md.render(
          dedent`Pour les personnes ne disposant pas de CPF, la demande s’effectue sur l’interface **Kairos**.
          \n &nbsp;
          Après validation dans votre espace France Travail, la demande de financement sera instruite par votre conseiller. Le délai de traitement de la demande varie de quelques jours à plusieurs semaines.  
          \n &nbsp;
          Pour les personnes disposant de CPF, la demande s’effectue depuis [moncompteformation.gouv.fr](https://www.moncompteformation.gouv.fr/).
          \n &nbsp;
          La validation du dossier à la session choisie s’effectue le le jour même ou le lendemain au plus tard. France Travail acceptera ou refusera la demande sous 15 jours maximum.
Il n’est plus possible d’effectuer de demande lorsque l’on est à moins d’un mois du début de la formation.`
        ),
      },
      {
        heading: 'Préparation Opérationnelle à l’Emploi Individuelle (POEI) - France Travail',
        text: md.render(
          dedent`Vous êtes demandeur d'emploi indemnisé ou non ? Trouvez une entreprise en mesure de vous embaucher après la formation, en CDD ou en CDI d'au moins 12 mois. Ainsi, vous pourrez faire une demande de Préparation Opérationnelle à l’Emploi Individuelle (POEI). Une partie de la formation sera financée par l'entreprise, une autre par France Travail et le reste à votre charge via un financement personnel ou l'utilisation de vos heures CPF.`
        ),
      },
      {
        heading: 'CSP - Contrat de Sécurisation Professionnelle',
        text: md.render(
          dedent`Il est possible d’obtenir un financement dans le cadre d’un CSP. Ce financement s’adresse aux ex-salariés des entreprises touchés par un licenciement économique. La demande s’effectue via l’interface Kairos.
          \n &nbsp;
          Après dépôt du dossier, le délai de validation varie de quelques jours à quelques semaines. Votre inscription à la session choisie sera définitive en cas d’accord de France Travail (sous réserve de places disponibles).`
        ),
      },
      {
        heading: 'Région AIRE - Île-de-France',
        text: md.render(
          dedent`La Région Ile de France peut financer à hauteur de 80% le coût de votre formation, si vous êtes inscrit à France Travail (indemnisé ou non) et que vous résidez en Ile de France. 
          \n &nbsp;
          La demande doit être effectuée au plus tôt, dès l'admission en formation.
          \n &nbsp;
          Le délai de traitement de la demande varie entre 4 et 8 semaines et la réponse de la Région est généralement donnée 1 mois avant le début de la formation, parfois moins. Il n’est plus possible de déposer une demande, 6 semaines (+ 2 jours) avant le début de la formation.
          \n &nbsp;
          En cas d'accord de la Région, votre inscription à la session choisie sera définitive (sous réserve de places disponibles), à réception du delta entre le montant de la subvention et le prix de la formation.`
        ),
      },
      {
        heading: "Association pour le Droit à l'Initiative Économique (ADIE)",
        text: md.render(
          dedent`L'ADIE vous permet d'obtenir un « Prêt Mobilité » à hauteur de 6.000€, avec des mensualités pouvant aller jusqu'à 42 mois. Les fonds peuvent être libérés très rapidement et vous avez la possibilité de **rembourser de manière anticipée**. Vous devez juste trouver une personne qui est prête à se porter garante pour votre prêt, à hauteur de 50 % du montant emprunté. Cette solution est très souvent utilisée par nos élèves, car le remboursement peut se faire de manière anticipée, ce qui est relativement économique pour l'emprunteur. Rendez-vous sur [le site de l'Adie](https://www.adie.org/pour-acheter-un-vehicule-ou-passer-mon-permis/#content_0) pour simuler votre financement.`
        ),
      },
      /* {
        heading: 'Région Hauts-de-France',
        text: md.render(
          dedent`La Région Hauts de France finance à hauteur de 5 000€ pour les personnes résidant dans la Région Hauts-de-France disposant d’un montant de CPF minimum de 250€.
          
          La demande s’effectue à partir de moncompteformation.gouv.fr. La validation est très rapide : le jour même ou le lendemain au plus tard. 
Votre inscription à la session choisie sera définitive à réception de la validation de prise en charge de la Région et/ou par le prélèvement d’un acompte de 2 000€.
          `
        ),
      },
      {
        heading: 'Région Occitanie',
        text: md.render(
          dedent`_L'aide proposée par cette région est actuellement à l'étude. Contactez-nous pour avoir plus d'informations._`
        ),
      },
      {
        heading: 'Région Pays de la Loire',
        text: md.render(
          dedent`_L'aide proposée par cette région est actuellement à l'étude. Contactez-nous pour avoir plus d'informations._`
        ),
      },
      {
        heading: 'France Relance',
        text: md.render(
          dedent`Pour les demandeurs d’Emploi disposant d’un minimum de 1€ sur le compte CPF, le dispositif France Relance permet d’obtenir 1 000€ supplémentaires.
          `
        ),
      }, */
    ],
  },
  {
    tab: 'Salarié',
    index: 2,
    content: [
      {
        heading: 'Transitions Pro (Ex-FONGECIF)',
        text: md.render(
          dedent`Transitions Pro est un dispositif régional qui permet à un employé d'être accompagné sur le financement d'un nouveau projet professionnel. Le salarié n'est pas obligé de prévenir son employeur pour faire une demande de financement. La demande doit être soumise trois à quatre mois avant le début de la formation car les délais d'analyse du dossier sont souvent longs. Transitions Pro a le droit de vous refuser jusqu'à 3 demandes de financement.`
        ),
      },
      {
        heading: 'ANFH',
        text: md.render(
          dedent`L'ANFH est l'organisme collecteur de fonds de formation pour la fonction publique hospitalière. Il permet à toute personne travaillant de le milieu hospitalier de bénéficier d'une formation, aux mêmes conditions et avantages que le dispositif Transitions Pro cité ci-dessus.`
        ),
      },
      {
        heading: 'Compte Personnel de Formation (CPF)',
        text: md.render(
          dedent`Le CPF permet à toute personne, salariée ou demandeur d’emploi, de participer au financement d'une formation. Notre formation est référencée sous le code CPF n°236956. Pour utiliser vos heures CPF, rendez-vous sur [moncompteformation.gouv.fr](https://www.moncompteformation.gouv.fr/) pour connaître la valeur de l'aide qui peut vous être attribuée. La validation du dossier à la session choisie s’effectue le jour même ou le lendemain au plus tard.
          \n &nbsp;
          Si vous financez personnellement le reste à charge de la formation, votre inscription à la session choisie s’effectuera lorsque vous aurez versé un acompte de 2.000€ par prélèvement bancaire.
          \n &nbsp;
          Si votre entreprise finance le reste à charge de la formation, votre inscription à la session choisie s’effectuera lorsque l’entreprise aura versé le solde par virement bancaire à réception de facture avant le début de la formation.
          \n &nbsp;
          La constitution du dossier CPF n’est plus possible à moins de 11 jours ouvrés du début de la formation.`
        ),
      },
      {
        heading: 'Opérateurs de Compétences (OPCO)',
        text: md.render(
          dedent`Votre entreprise cotise chaque année, pour la formation de ses salariés. Elle a droit annuellement à un certain montant pour la montée en compétence de ses équipes. Vous pouvez vous rapprocher de votre service RH pour faire une demande de formation, en présentant un devis.`
        ),
      },
      {
        heading: 'Entreprise',
        text: md.render(
          dedent`Si votre entreprise finance la formation, votre inscription à la session choisie sera validée dès réception du paiement de la formation par virement bancaire.
          `
        ),
      },
    ],
  },
  {
    tab: 'Indépendant',
    index: 3,
    content: [
      {
        heading: 'Auto-entrepreneurs',
        text: md.render(
          dedent`Chaque mois, un auto-entrepreneur cotise à la formation professionnelle.
          \n &nbsp;
          Pour connaître le Fonds d’Assurance Formation (FAF) afin de demander le financement, il convient de télécharger sur le site [autoentrepreneur.urssaf.fr](https://www.autoentrepreneur.urssaf.fr/portail/accueil.html) l’attestation de contribution formation professionnelle.
          \n &nbsp;
          Les différents fonds existants :
          \n &nbsp;
          - Fonds Interprofessionnel de Formation des Professionnels Libéraux (FIFPL)
          - AGEFICE
          - La Chambre des Métiers régionale et le FAFCEA pour les artisans
          \n &nbsp;
          Le dossier doit être constitué le plus tôt possible, dès admission.`
        ),
      },
      {
        heading: 'Fonds Interprofessionnel de Formation des Professionnels Libéraux (FIFPL)',
        text: md.render(
          dedent`Cette aide annuelle permet aux indépendants de se former, elle peut être valorisée entre 1.400€ et 2.500€. Vous ne pourrez toucher cette subvention qu'à la fin de la formation (en guise de remboursement de frais de formation) et il faudra que vous ayez déjà cotisé pendant plusieurs mois pour en bénéficier.`
        ),
      },
      {
        heading: 'Aide aux Intermittents du Spectacle',
        text: md.render(
          dedent`Si vous êtes intermittent, vous devez dépendre de l'AFDAS qui est en mesure d'assurer le financement de votre formation, totalement ou partiellement. C'est cet organisme qui paiera directement l'organisme de formation et vous n'aurez pas de frais à avancer.`
        ),
      },
    ],
  },
  {
    tab: 'Alternance',
    index: 4,
    content: [
      {
        heading: 'Si vous êtes un alternant potentiel',
        text: md.render(
          dedent`Si vous êtes demandeur d'emploi et que vous avez choisi de vous former en alternance, le coût de la formation est pris en charge intégralement par l'entreprise d'accueil et/ou par l'intermédiaire de son opérateur de compétences (OPCO). **Vous n'aurez rien à payer** ; nous accompagnons l'entreprise d'accueil sur la partie financière.
          \n &nbsp;
          En tant qu'alternant, vous serez rémunéré sur la base du SMIC, en fonction de votre âge et du type de contrat. Vous pouvez calculer votre salaire en utilisant [le simulateur](https://www.alternance.emploi.gouv.fr/simulateur-alternant/etape-1) de rémunération des alternants, mis à disposition par l'État.`
        ),
      },
      {
        heading: 'Si vous êtes une entreprise qui recrute un alternant',
        text: md.render(
          dedent(`Le recrutement d'un alternant peut être financé grâce à plusieurs dispositifs.
          \n &nbsp;
          Tout d'abord, sachez que le coût global de la formation de l'alternant s'élève à **13.000€ HT**, pour 721h de formation.
          \n &nbsp;
          Votre OPCO est en mesure de financer une partie de la formation, en fonction du type de contrat : contrat d'apprentissage ou contrat de professionnalisation. Dans tous les cas, vous aurez un reste à charge car l'OPCO ne prendra pas en charge 100% du coût de la formation. Pour connaître le montant de la prise en charge de l'alternance par votre OPCO, vous pouvez leur en faire la demande directement.
          \n &nbsp;
          Si c'est un contrat d'apprentissage, ce sera un forfait annuel qui sera versé eu prorata, en fonction de la durée totale de l'alternance. Si c'est un contrat de professionnalisation, l'OPCO vous alloue un budget à l'heure de formation, entre 8 et 17€ de l'heure HT, en fonction du diplôme préparé. Le diplôme préparé au Reacteur étant de niveau 6 ([RNCP 37873](https://www.francecompetences.fr/recherche/rncp/37873/)), les montants alloués par les OPCO sont souvent généreux. Les OPCO proposent souvent des simulateurs pour calculer le coût d'un alternant, comme [celui de l'OCPO Atlas](https://www.opco-atlas.fr/simulateur-cout-contrat-alternance), par exemple.
          \n &nbsp;
          Pour payer le reste à charge, le demandeur d'emploi peut faire une demande à France Travail (ex Pôle Emploi) pour signer une Préparation Opérationnelle à l'Embauche Individuelle (POEI). C'est une convention tripartite avec l'organisme de formation, qui permet d'obtenir jusqu'à **3.200€** d'aide (8 euros par heure de formation, dans la limite de 400 heures).
          \n &nbsp;
          Ensuite, il existe d'autres aides données par l'État, pour absorber les coûts liés au salaire de l'alternant :
          \n &nbsp;
          - **4.000€** d'[aide à l'embauche](https://www.agefiph.fr/actualites-handicap/plan-france-relance-les-employeurs-peuvent-beneficier-dun-soutien-pour-le) pour un travailleur en situation de handicap (RQTH), via l'AGEFIPH
          - **6.000€** d'[aide à l'embauche](https://entreprendre.service-public.fr/vosdroits/F23556) pour un demandeur d'emploi de moins de 30 ans
          - **2.000€** d'[aide à l'embauche](https://www.pole-emploi.fr/employeur/aides-aux-recrutements/les-aides-a-lembauche/aide-a-lembauche-dun-demandeur-d.html) pour un demandeur d'emploi d'au moins 26 ans
          - **4.000€** d'[aide à l'embauche](https://www.pole-emploi.fr/employeur/aides-aux-recrutements/les-aides-a-lembauche/embauche-de-de-de-45-ans-et-plus.html) pour un demandeur d'emploi de plus de 45 ans
          - **5.000€** sur 2 ans, pour une embauche en CDD d'au moins 6 mois (2.500€/an) grâce au dispositif [Emplois Francs](https://travail-emploi.gouv.fr/emploi-et-insertion/emplois-francs/article/embaucher-une-personne-en-emploi-franc)
          \n &nbsp;
          Enfin, sachez que la rémunération de votre alternant doit respecter un certain barème. Vous pouvez retrouver toutes les infos importantes sur [le site du gouvernement](https://www.service-public.fr/particuliers/vosdroits/F2918/personnalisation/resultat?lang=&quest0=0&quest=).`)
        ),
      },
    ],
  },
];

export default FinanceOptions;
