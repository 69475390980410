import React, { useState, useEffect } from 'react';
import { Heading, Content } from './elements';
import { TabsListMobile } from '../TabsList';
import { animatedScrollTo } from 'src/utils/animatedScrollTo';
import { Easing } from 'src/utils/Easings';
import colors from 'src/style/colors';

const FinanceSmall = ({ contents, fromFormationsPage }) => {
  const [unfolded, setUnfolded] = useState(
    fromFormationsPage && fromFormationsPage === 'Alternance' ? 4 : null
  );
  const container = React.useRef();

  useEffect(() => {
    if (unfolded !== null && container.current) {
      const itemPos = container.current.getBoundingClientRect();

      const windowHeight = window.innerHeight;

      const target = windowHeight - 40;
      const bottomDist = target - itemPos.bottom;

      if (bottomDist < 0) {
        const scrollDest = window.scrollY + Math.abs(bottomDist);
        const actions = animatedScrollTo(window, scrollDest, {
          duration: 300,
          easing: Easing.easeInOutQuart,
        });

        const onWheel = () => {
          actions.stop();
        };

        window.addEventListener('wheel', onWheel);

        return () => {
          actions.stop();
          window.removeEventListener('wheel', onWheel);
        };
      }
    }
  });

  return (
    <div css={{ marginTop: 40 }}>
      {contents.map((item, index) => (
        <div
          key={item.tab}
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TabsListMobile
            tabItem={item.tab}
            isUnfolded={unfolded === index}
            onClick={() => {
              setUnfolded((prev) => (prev === index ? null : index));
              // setUnfolded(index);
            }}
            ref={unfolded === index ? container : undefined}
          >
            {unfolded === index && (
              <div css={{ width: '100%' }}>
                {contents[index].content.map((content, index) => (
                  <div
                    key={content.heading}
                    css={{
                      '&:not(:last-child)': {
                        paddingBottom: 32,
                      },
                    }}
                  >
                    <Heading>{content.heading}</Heading>
                    <Content
                      dangerouslySetInnerHTML={{ __html: content.text }}
                      css={{
                        '& a': {
                          color: colors.purple,
                          textDecoration: 'none',
                        },
                        '& ul': { listStyleType: 'disc', marginLeft: 40 },
                        '& strong': {
                          display: 'inline-block',
                          marginTop: 10,
                          fontSize: 17,
                        },
                      }}
                    />
                  </div>
                ))}
              </div>
            )}
          </TabsListMobile>
        </div>
      ))}
    </div>
  );
};

export default FinanceSmall;
