import raf from 'raf';
import { Easing } from './Easings';

const clamp = (val, min, max) => Math.min(Math.max(min, val), max);

function scrollTo(container, val) {
  if (container === window) {
    window.scrollTo({ top: val });
  } else {
    container.scrollTop = val;
  }
}

function getScrollTop(container) {
  if (container === window) {
    return window.scrollY;
  }
  return container.scrollTop;
}

function getScrollSize(container) {
  if (container === window) {
    return document.body.clientHeight - window.innerHeight;
  }
  return container.scrollHeight - container.clientHeight;
}

export function animatedScrollTo(scrollContainer, destination, options = {}) {
  const { duration = 200, easing = Easing.linear } = options;
  const startScroll = getScrollTop(scrollContainer);
  const startTime = Date.now();
  const endTime = startTime + duration;
  const scrollSize = getScrollSize(scrollContainer);
  const normalizedDestination = clamp(destination, 0, scrollSize);
  const scrollDistance = normalizedDestination - startScroll;

  let running = true;

  function stop() {
    running = false;
  }

  function scroll() {
    const now = Date.now();
    const progress = Math.min(1, (now - startTime) / duration);
    const timeFunction = easing(progress);
    const nextScrollValue = startScroll + timeFunction * scrollDistance;
    const nextScrollValueNormalized = Math.min(scrollSize, Math.max(0, nextScrollValue));
    scrollTo(scrollContainer, nextScrollValueNormalized);

    if (nextScrollValueNormalized === normalizedDestination) {
      stop();
    }

    if (running && now >= endTime) {
      stop();
      scrollTo(scrollContainer, nextScrollValueNormalized);
    }

    if (running) {
      raf(scroll);
    }
  }

  scroll();

  return {
    isScrolling: () => running,
    stop: () => stop(),
  };
}
