import React from 'react';
/* --- import Components ----*/
import { SectionOverlap } from '../../Section';
import FinanceLarge from './FinanceLarge';
import FinanceSmall from './FinanceSmall';
import FinanceOptions from 'src/data/FinanceOptions';
/* --- import Styles  ----*/
import { useMediaQueries } from '../../../utils/MediaQueries';

const Finance = React.memo(({ id, fromFormationsPage }) => {
  const mqs = useMediaQueries();
  return (
    <SectionOverlap marginTop={-85} theme="secondary" childrenIsTabs={true}>
      {mqs.tabletLandscapeUp ? (
        <FinanceLarge contents={FinanceOptions} fromFormationsPage={fromFormationsPage} />
      ) : (
        <FinanceSmall contents={FinanceOptions} fromFormationsPage={fromFormationsPage} />
      )}
    </SectionOverlap>
  );
});

export default Finance;
