import React, { useState } from 'react';
/* --- import Components ----*/
import { TabsTabletLandscapeUp, Heading, Content } from './elements';
import { TabsList } from '../TabsList';
/* --- import Styles  ----*/
import colors from 'src/style/colors';

const FinanceLarge = ({ contents, fromFormationsPage }) => {
  const [unfolded, setUnfolded] = useState(fromFormationsPage && fromFormationsPage === 'Alternance' ? 4 : 0);

  return (
    <div
      css={{
        backgroundColor: colors.white,
        borderRadius: 10,
        '@media only screen and (min-width: 900px) and (max-width: 1199px)': {
          marginTop: -85,
        },
      }}
    >
      <TabsTabletLandscapeUp>
        {contents.map((item, index) => (
          <TabsList
            key={item.tab}
            tab={item.tab}
            isOpen={index === unfolded}
            onClick={() => {
              setUnfolded(index);
            }}
          />
        ))}
      </TabsTabletLandscapeUp>

      <ul
        css={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
          padding: '32px 64px',
        }}
      >
        {contents[unfolded].content.map((content) => (
          <li
            key={content.heading}
            css={{
              display: 'flex',
              flexDirection: 'column',
              listStyle: 'none',
              '&:not(:last-child)': {
                paddingBottom: 32,
              },
            }}
          >
            <Heading>{content.heading}</Heading>
            <Content
              dangerouslySetInnerHTML={{ __html: content.text }}
              css={{
                '& a': {
                  color: colors.purple,
                  textDecoration: 'none',
                },
                '& ul': { listStyleType: 'disc', marginLeft: 40 },
                // '& strong': {
                //   fontSize: 16,
                // },
              }}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default FinanceLarge;
