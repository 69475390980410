import React from 'react';
import Layout from '../components/Layout';
import { TopbarStaticSpace } from 'src/components/Topbar';
import { Section } from 'src/components/Section';
import { AnchorButton } from 'src/components/Button';
import { Finance } from 'src/components/Bootcamp/Finance';

/* --- Import Style --- */
import { mediaQueries } from 'src/style/breakpoints';
import { BtnWrapper, HeroWrapper, HeroLeft } from 'src/components/Hero/elements';
import { HeaderTitle, HeaderDescription } from 'src/components/Typography';

const Financements = ({ location }) => {
  return (
    <Layout
      pathname={location.pathname}
      businessType="B2C|B2B"
      pageName="Financements"
      pageType="page"
      metaTitle="Financements | Le Reacteur"
      metaDescription="Retrouvez les aides au financement les plus adaptées à votre situation et rejoignez le bootcamp du Reacteur"
    >
      <TopbarStaticSpace />
      {/* <HeroBG80vh> */}
      <Section theme="primary" hero={true} margin>
        {/* <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <HeaderTitle>Comment financer une formation ?</HeaderTitle>
          <HeaderDescription
            css={{
              marginTop: 32,
            }}
          >
            Plusieurs subventions sont possibles pour financer votre formation. <br />
            Nous sommes à votre disposition pour vous accompagner au mieux dans la construction de
            votre dossier. <br /> Vous pouvez également{' '}
            <StyledLink to="/contact/" color={colors.purple}>
              nous contacter{' '}
            </StyledLink>
            ou participer à l'une de nos{' '}
            <StyledLink to="/evenements/" color={colors.purple}>
              réunions d’information
            </StyledLink>
            . Nous pourrons ainsi répondre à toutes vos questions, qu'elles soient techniques ou
            administratives.
          </HeaderDescription>
        </div> */}
        <HeroWrapper>
          <HeroLeft css={{ flex: 1 }}>
            <HeaderTitle>Comment financer une formation ?</HeaderTitle>
            <HeaderDescription
              css={{
                marginTop: 30,
                textAlign: 'center',
                [mediaQueries.desktopUp]: {
                  marginRight: 60,
                  textAlign: 'left',
                },
              }}
            >
              <p
                css={{
                  fontWeight: 'bold',
                  fontSize: 17,
                  marginBottom: 20,
                }}
              >
                Dernière mise à jour : le 30/05/2024
              </p>
              Ci-dessous sont listés les dispositifs de financements possibles pour nos formations.
              <br />
              <br />
              Nous sommes à votre disposition pour vous accompagner au mieux dans la construction de
              votre dossier, n'hésitez pas à nous contacter, pour répondre à toutes vos questions,
              qu'elles soient techniques ou administratives.
            </HeaderDescription>
            <BtnWrapper>
              <AnchorButton
                to="/evenements/"
                css={{
                  marginRight: 20,
                  [mediaQueries.phoneOnly]: {
                    marginBottom: '20px',
                    width: '100%',
                    marginRight: 0,
                  },
                }}
              >
                Participer à la réunion d'information
              </AnchorButton>

              <AnchorButton
                secondary
                to="/contact/"
                css={{
                  [mediaQueries.phoneOnly]: {
                    marginBottom: '20px',
                    width: '100%',
                  },
                }}
              >
                Nous contacter
              </AnchorButton>
            </BtnWrapper>
          </HeroLeft>
        </HeroWrapper>
      </Section>
      {/* </HeroBG80vh> */}

      <Finance
        id="financements"
        fromFormationsPage={
          location.state && location.state.fromFormationsPage
            ? location.state.fromFormationsPage
            : null
        }
      />
    </Layout>
  );
};

export default Financements;
